import { Match, Switch, createEffect, createMemo, splitProps } from "solid-js";
import { ShowMaterialViewProps } from "../../models";
import { getMaterialType } from "../../methods/get-material-type";
import { Atom, state } from ":mods";
import { useParams } from "@solidjs/router";
import { getMaterialDetails } from "../../apis";
import { ShowMaterialLesson } from "./lesson";
import { ShowMaterialTask } from "./task";
import { ShowMaterialQuestion } from "./question";
import { ShowMaterialLessonStatic } from "./lesson-static";
import { ShowSideNotes } from "../my-notes";
export function ShowMaterial(props: ShowMaterialViewProps) {
  const [local, others] = splitProps(props, ["$", "material"]);
  const params = useParams();
  const materialId = local.material.id ?? params.id;
  const is_static_lesson = local.material.design_name && local.material.design_name.toLowerCase().includes("section");
  const $details = state.createAsync([getMaterialDetails, materialId], {
    // manualFetch: { startSuccess: is_static_lesson },
    // initialValue: {
    //   data: local.material,
    // },
  });
  const type = createMemo(() => {
    if (!$details.state.success) {
      return undefined;
    }
    const s = $details.value;
    return getMaterialType(s.data);
  });

  // if (!is_static_lesson) {
  //   $details.refetch();
  // }
  // const type = $details.derive((s) => getMaterialType(s.data));
  // local.$.actions.setLayout({
  //   titleBorder: !type() || type() === "lesson"? false : true,
  // });

  local.$.actions.setLayout({
    // sideInfo: [
    //   {
    //     element: () => <ShowSideNotes />,
    //   },
    // ],
  });
  createEffect(() => {
    // console.log("show material effect :: ");
    if (type() && type() !== "lesson") {
      local.$.actions.updateLayout({
        title: () => (
          <div class="flex flex-col ">
            <h3>{$details.value?.data?.name ?? ""}</h3>
            <h1>{$details.value?.data?.description ?? ""}</h1>
          </div>
        ),
        titleBorder: true,
      });
    }
  });

  // static material solution, no need to fetch..
  if (type() === "lesson") {
    return <ShowMaterialLessonStatic {...local.material} $={local.$} />;
  }
  return (
    <Switch fallback={<div>unknown resource state </div>}>
      <Match when={$details.state.loading}>
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Material"
            msg="Loading content..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      </Match>
      <Match when={$details.state.failed}>An error occured while fetching stage data {$details.error} ...</Match>
      <Match when={$details.state.success}>
        <Switch fallback={<div>unknown material content type</div>}>
          {/* lesson */}
          <Match when={type() === "lesson"}>
            <ShowMaterialLessonStatic {...$details.unwrap.value.data} $={local.$} />
            {/* <ShowMaterialLesson $={local.$} content={$details.value.data.content as any} /> */}
          </Match>
          {/* question */}
          <Match when={type() === "question"}>
            {/* these are qustions */}
            <ShowMaterialQuestion
              $={local.$}
              elements={{
                data: $details.unwrap.value.data,
              }}
            />
          </Match>
          {/* task */}
          <Match when={type() === "task"}>
            <ShowMaterialTask
              $={local.$}
              elements={{
                data: $details.unwrap.value.data as any,
              }}
            />
          </Match>
        </Switch>
      </Match>
    </Switch>
  );
}
